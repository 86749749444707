<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="inspectionCategories"
      :items-per-page="10"
      :search="search"
      :header-props="{ sortIcon: null }"
      sort-by="name"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="page-title">
            <v-icon class="secondary--text" left light>{{ icons.mdiClipboardCheck }}</v-icon>
            {{ $route.meta.title }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-layout>
            <v-spacer></v-spacer>
            <div v-for="(permission, index) in permission" :key="index">
              <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                  <v-btn
                    v-show="user_submenu.create == 1 && permission.role_id == 3"
                    color="accent"
                    rounded
                    @click.prevent="addnewInspectionCategory"
                  >
                    <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                    <span>Create New </span>
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <v-btn v-show="currentUser.role_id == 1" color="accent" rounded @click.prevent="addnewInspectionCategory">
                <v-icon left dark>{{ icons.mdiPlus }}</v-icon>
                <span>Create New </span>
              </v-btn>
            </div>
          </v-layout>
        </v-toolbar>
        <v-divider class="mb-2"></v-divider>
        <v-text-field
          color="accent"
          class="mx-2"
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(inspectionCategory, index1) in items" :key="index1">
            <td>{{ inspectionCategory.name }}</td>
            <td>{{ formatDate(inspectionCategory.created_at) }}</td>
            <td>
              <v-layout class="mx-1">
                <div v-for="(permission, index) in permission" :key="index">
                  <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                    <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                      <v-btn
                        v-show="user_submenu.update == 1 && permission.role_id == 3"
                        color="primary"
                        @click.prevent="editInspectionCategory(inspectionCategory)"
                        fab
                        x-small
                        dark
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-show="currentUser.role_id == 1">
                  <v-btn color="primary" @click.prevent="editInspectionCategory(inspectionCategory)" fab x-small dark>
                    <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                </div>
                <div>
                  <div v-for="(permission, index) in permission" :key="index">
                    <div v-for="(usermenu, index1) in permission.usermenus" :key="index1">
                      <div v-for="(user_submenu, index2) in usermenu.user_submenus" :key="index2">
                        <v-btn
                          v-show="user_submenu.delete == 1 && permission.role_id == 3"
                          @click.prevent="deleteInspectionCategories(inspectionCategory.inspection_cat_id)"
                          color="error"
                          fab
                          x-small
                          dark
                          class="mx-1"
                        >
                          <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn
                      v-show="currentUser.role_id == 1"
                      @click.prevent="deleteInspectionCategories(inspectionCategory.inspection_cat_id)"
                      color="error"
                      fab
                      x-small
                      dark
                      class="mx-1"
                    >
                      <v-icon>{{ icons.mdiDeleteForeverOutline }}</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:no-data>
        <v-btn color="accent" @click="getInspectionCategories"> Reset </v-btn>
      </template>
    </v-data-table>

    <add-inspectioncategory
      :dialog.sync="showNewInspectionCategoryDialog"
      @open-dialog="showNewInspectionCategoryDialog = true"
      @close-dialog="showNewInspectionCategoryDialog = false"
    />

    <edit-inspectioncategory
      :dialog.sync="showEditInspectionCategoryDialog"
      :inspectionCategory="inspectionCategory"
      @open-dialog="showEditInspectionCategoryDialog = true"
      @close-dialog="showEditInspectionCategoryDialog = false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  mdiAccountTie,
  mdiMagnify,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteForeverOutline,
  mdiPhone,
  mdiEmail,
  mdiClipboardCheck,
} from '@mdi/js'

import AddNewInspectionCategoryDialog from '@/views/inspectioncategory/AddNewInspectionCategory.vue'
import EditInspectionCategoryDialog from '@/views/inspectioncategory/EditInspectionCategory.vue'

export default {
  components: {
    'add-inspectioncategory': AddNewInspectionCategoryDialog,
    'edit-inspectioncategory': EditInspectionCategoryDialog,
  },
  created: function () {
    this.getInspectionCategories()
    this.getAuthorizations()
    window.mitt.on('loadInspectionCategory', () => {
      this.getInspectionCategories()
    })
  },

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      inspectionCategories: [],
      inspectionCategory: {},
      permission: {},
      search: '',
      showNewInspectionCategoryDialog: false,
      showEditInspectionCategoryDialog: false,
      icons: {
        mdiAccountMultiple,
        mdiAccountTie,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteForeverOutline,
        mdiMagnify,
        mdiDotsVertical,
        mdiPhone,
        mdiEmail,
        mdiClipboardCheck,
      },
    }
  },
  methods: {
    getInspectionCategories() {
      this.$store
        .dispatch('inspection/fetchInspectionCategories')
        .then(response => {
          this.inspectionCategories = this.fetchInspectionCategories
        })
        .catch(err => {
          console.log(err)
        })
    },

    getAuthorizations: function () {
      this.$store
        .dispatch('authorization/fetchModules', {
          user_id: this.currentUser.user_id,
          menu_id: 7,
          submenu_id: 7,
        })
        .then(response => {
          this.permission = this.fetchAuthorizations
        })
        .catch(err => {
          console.log(err)
        })
    },
    deleteInspectionCategories(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inspection/deleteInspectionCategories`, {
                inspection_cat_id: id,
              })
              .then(response => {
                this.getInspectionCategories()
              })
          }
        })
    },

    addnewInspectionCategory: function () {
      this.showNewInspectionCategoryDialog = true
    },
    editInspectionCategory: function (inspectionCategory) {
      this.inspectionCategory = Object.assign({}, inspectionCategory)
      this.showEditInspectionCategoryDialog = true
    },
    formatDate(value) {
      return moment(value).format('l')
    },
  },
  computed: {
    ...mapGetters({
      fetchInspectionCategories: 'inspection/fetchInspectionCategories',
      fetchAuthorizations: 'authorization/fetchAuthorizations',
    }),

    currentUser() {
      return this.$store.getters['authentication/currentUser']
    },
  },
}
</script>

<style>
</style>