<template>
  <v-dialog :value="dialog" @input="$emit('input', $event)" max-width="40%" persistent>
    <v-card elevation="1">
      <v-toolbar flat color="primary" light>
        <v-toolbar-title class="white--text">Edit Inspection Category</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col md="12" cols="12">
              <v-text-field
                color="secondary"
                v-model="inspectionCategory.name"
                label="Name"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="mt-n5"></v-divider>
        <v-card-actions>
          <v-btn color="error" plain @click.prevent="deleteInspectionCategory(inspectionCategory.inspection_cat_id)">
            <v-icon right dark>{{ icons.mdiDeleteForeverOutline }}</v-icon>
            <span>Delete</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded @click.prevent="saveInspectionCategory">
            <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
            <span>Save</span>
          </v-btn>
          <v-btn color="secondary" rounded @click.prevent="close">
            <v-icon right dark>{{ icons.mdiClose }}</v-icon>
            <span>Cancel</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import mitt from 'mitt'
window.mitt = window.mitt || new mitt()
import { mdiPlus, mdiInformation, mdiContentSave, mdiDeleteForeverOutline, mdiClose, mdiCash } from '@mdi/js'

export default {
  props: {
    dialog: Boolean,
    inspectionCategory: Object,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
        mdiCash,
        mdiDeleteForeverOutline,
      },
    }
  },
  methods: {
    saveInspectionCategory: function () {
      this.$store
        .dispatch(`inspection/updateNewInspectionCategory`, {
          inspection_cat_id: this.inspectionCategory.inspection_cat_id,
          data: {
            inspection_cat_id: this.inspectionCategory.inspection_cat_id,
            name: this.inspectionCategory.name,
          },
        })
        .then(response => {
          window.mitt.emit('loadInspectionCategory', this.close())
        })
        .catch(error => {
          console.log(error)
        })
    },

    deleteInspectionCategory(id) {
      this.close()
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        })

        .then(result => {
          if (result.isConfirmed) {
            this.$swal.fire({
              title: 'Deleted!',
              text: 'The record has been deleted.',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            })
            this.$store
              .dispatch(`inspection/deleteInspectionCategories`, {
                inspection_cat_id: id,
              })
              .then(response => {
                window.mitt.emit('loadInspectionCategory', this.close())
              })
          }
        })
    },

    close() {
      this.$emit('close-dialog')
    },
  },
}
</script>

<style>
</style>